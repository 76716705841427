/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
/* @import '~antd/dist/antd.css'; */

.ant-layout {
  background-color: #ffffff;
  min-width: 700px;
  max-width: 1800px;
}
#header {
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
}
.ant-card-body .ant-collapse {
  margin-bottom: 10px;
}
.ant-card-body .ant-collapse:last-child {
  margin-bottom: 0px;
}

.ant-collapse-header-text {
  width: 100%;
}